<mat-drawer-container class="drawer-container">
    <mat-drawer #mainMenuDrawer position="end" autoFocus="false">
        <mat-toolbar id="main-drawer-toolbar" class="gray-toolbar ">
            <div id="logo-link">
                <a mat-button href="/">Blue Cardinal</a>
            </div>
            <div id="logo-link">
                <button mat-icon-button (click)="closeMenu()">
                    <mat-icon fontIcon="arrow_forward_ios"></mat-icon>
                </button>
            </div>
        </mat-toolbar>
        <div>
            <mat-nav-list>
                <a mat-list-item href="pricing">Pricing</a>
            </mat-nav-list>
        </div>
        <mat-toolbar id="main-drawer-toolbar-footer" class="primary-toolbar">
            <a mat-stroked-button id="sign-in-mobile-toolbar" class="white-border" [href]="this.signInURL">Sign In</a>
            <a mat-raised-button id="sign-up-mobile-toolbar" class="accent-btn" [href]="this.signUpURL">Sign Up</a>
        </mat-toolbar>
    </mat-drawer>
    <mat-drawer-content>
        <app-main-toolbar (openMenu)="openMenu()"></app-main-toolbar>
        <div id="page-container" class="primary-background">
            <router-outlet></router-outlet>
        </div>
        <footer>
            <div id="company-logo">
                <div id="footer-logo-container"></div>
            </div>
        </footer>
    </mat-drawer-content>
</mat-drawer-container>